import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import './assets/animate.min.css'
import { legacyLogicalPropertiesTransformer, StyleProvider } from '@ant-design/cssinjs';
import { Provider } from 'react-redux';
import store from './store/store';
var style: any = require('@/theme.module.less').default
console.log(style)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider 
  theme={{ 
    token: { colorPrimary: style.color },
    components:{
      Slider:{
        controlSize:50,
        dotSize:15,
        handleSize:15,
        handleSizeHover:15,
        handleColor:style.colorBorder,
        handleActiveColor:style.colorBorder,
        dotActiveBorderColor:style.colorBorder,
        // railBg:'#fff',
        // railHoverBg:'#fff',
        trackBg:style.colorBorder,   
        trackHoverBg:style.colorBorder,
        railSize:15,
      },
      InputNumber:{
        activeBorderColor:style.color,
        hoverBorderColor:style.color,
        handleWidth:0,
        controlWidth:50,
      },
      Input:{
        activeBorderColor:style.color,
        hoverBorderColor:style.color,
      },
      Button:{
        defaultBg:style.color,
        defaultColor:'#fff'
      },

    }
    }}>
    <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
      <Provider store={store}>
        <App />
      </Provider>
    </StyleProvider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
