import type { CSSProperties } from 'react';
import React from 'react';
import './index.less';




const FlexCube = (props:any) => {

  const {HeadLine,matter,matter2,img,order} = props

  return (
    <div className="flex">
          <div className="FlexImg animated bounceInLeft">      
              <img src={img} alt="" />
          </div>
          <div className='FlexText animated bounceInLeft' style={{order:`${order}`}} >    
              <h3>{HeadLine}</h3>
              <p>{matter}</p>
              <p>{matter2}</p>
          </div>
    </div>

  );
};

export default FlexCube;

