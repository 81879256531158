import axios, { AxiosResponse } from 'axios';
import hexMD5 from './md5.js';
import { App, message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface.js';
export var request_ip = '/api';

export const service_ip = (function () {

  if (process.env.NODE_ENV == "development") {
    return 'http://' + request_ip
  } else {
    if (window.location.href.indexOf('https://') != -1) {
      return 'https://' + request_ip;
    } else {
      return 'http://' + request_ip;
    }
  }
})();


// 假设我们要获取名为'myKey'的缓存项  
export function getLocalStorageItem(key: string) {

  const dataTxt: string = localStorage.getItem(key) || '';
  if (dataTxt.length) {
    return JSON.parse(dataTxt)
  }
  return dataTxt
}

export function setLocalStorageItem(key: string, value: any) {
  const dataTxt = JSON.stringify(value)
  localStorage.setItem(key, dataTxt)
}

export function clearLocalStorageItem(key: string) {
  localStorage.removeItem(key)
}

export const fetch = <
  K,
  T extends { result?: number; errno?: string; data: K }
>(
  params: any,
  message?:MessageInstance
) => {
  // const {message} = App.useApp()
  return new Promise<K>((resolve, reject) => {

      params.url = `${request_ip}${params.url}`;
    const method = params.method || 'post'
    const data = method == 'post' ? paramsHandler(params.data || {}) : params.data;
    console.log('data', data)
    axios({
      ...params,
      method: method,
      data: data,
      params: method == 'get' ? data : {},
      withCredentials: true,
      timeout: 50000
    })
      .then((res: AxiosResponse<T>) => {
        if (typeof res == 'string') {
          res = JSON.parse(res);
        }

        const rResult: any = res.data;
        if (rResult && rResult.code == 200) {
          console.log(rResult.data)
          resolve(rResult.data);
        } else {
          console.log('进来了')
          message?.open({content:'error, please try again'})
        }
      })
      .catch((error) => {
        console.log('进来了2')
        reject(error);
      });
  });
};

export const pxTovw = (value: number) => {
  const width = document.body.clientWidth
  const newValue = value / 1920 * width
  return newValue
}
/**
 * post参数处理
 */
export const paramsHandler = function (v: any) {
  var formData = new FormData()
  for (let i in v) {
    formData.append(i, v[i])
  }
  console.log('formData', formData)
  return formData;
};

export const tempToDate = (temp: number) => {
  const date = new Date(temp)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}


export interface listType {
  loading: boolean;
  error: boolean;
  finished: boolean;
  page_no: number;
  final_page: number;
}





//错误码
export const msgCode = (n: string) => {
  if (n === undefined) {
    return '未知错误';
  }
  n = n.toString();
  switch (n) {
    case '100':
      return 'json解析错误';
    default:
      return '未知错误';
  }
};


export function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function base64ToFile(base64Str: string, fileName: string, mimeType: string = 'image/png') {
  // 移除Base64字符串的头部（如果有的话）  
  let data = base64Str.replace(/^data:image\/\w+;base64,/, "");

  // 使用Blob将Base64字符串转换为二进制数据  
  let byteCharacters = atob(data);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], { type: mimeType });

  // 创建一个File对象（如果需要的话）  
  let file = new File([blob], fileName, { type: mimeType });

  // 返回一个包含File对象和URL的对象（URL可用于<img>标签）  
  return {
    file: file,
    url: URL.createObjectURL(blob)
  };
}
//获取图片的宽高
export function getImageDimensions(url: string) {
  return new Promise((resolve, reject) => {
    // 创建一个新的Image对象  
    const img = new Image();
    console.log(img)
    // 当图片加载完成时，调用resolve，传入图片的宽高信息  
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      });
    };

    // 如果图片加载失败，调用reject  
    img.onerror = (error) => {
      reject(error);
    };

    // 设置图片的src属性以开始加载图片  
    img.src = url;
  });
}

//判断设备是不是移动端
export const isMobile = () => {
  const userAgent = navigator.userAgent;
  return userAgent.match(/(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*)/i);
}

export function generateUUID() {
  //获取唯一id
  // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
  //     var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);  
  //     return v.toString(16);  
  // });  

  return Math.floor(Math.random() * 1000000000)
}

// type rule={require:boolean,tips:string,reg?:RegExp}
export const useValidate = () => {
  const { message: msg } = App.useApp()
  function validate(rule: any, form: any) {
    for (let i in rule) {


      if (rule[i].require) {
        if (!form[i]) {
          console.log(msg)
          msg.error(rule[i].tips)
          return false
        }
        if (rule[i].reg) {
          const reg = rule[i].reg
          if (reg?.test(form[i])) {
            msg.error({ content: rule[i].tips })
            return false
          }
        }
      }
    }
    return true
  }
  return [
    validate
  ]
}