import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { Button } from 'antd';

const YaberPane = (props:any) => {

 const { HeadLine,Expound,img,href,display } = props
  return (
    <div className="yaber_cube">
       <div className="leftImg">
          <img src={img} alt="" />
       </div>
       <div className="rightTxt">
          <h4>{HeadLine}</h4>
          <p>{Expound}</p>
          <Button href={href} shape="round" style={{ display: `${display}` }}>Get started</Button>
       </div>
    </div>
  );
};

export default YaberPane;

