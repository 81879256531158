import React, { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';
import { Tabs,Button,Collapse} from 'antd';
import './index.less';
import img from '../../images/home/2.png';


import AskCube from './AskCube/index';
import BigFlexCube from './BigFlexCube/index';
import SmallFlexCube from './SmallFlexCube/index';
import CategoryCube from './CategoryCube/index';
import SwitchCube  from './SwitchCube/index';
import BusinessLogoCube from './BusinessLogoCube/index';
import WorkCube from './WorkCube/index';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Animated } from 'react-animated-css';

const HomePage = (props: any) => {
  
  const { TabPane } = Tabs;
  useEffect(() => {
    return () => {
    };
  },[])

  const tabChange = ()=>{

  }
  const LogoList:any = [
    require('@/images/home/bus_logo1.png'),
    require('@/images/home/bus_logo2.png'),
    require('@/images/home/bus_logo3.png'),
  ]

  return (
    <div className="home_page">


      <section className="padding-global padding-section-medium">
          <WorkCube/>
      </section>




       <section className="padding-global padding-section-medium flex flex_center flex_justify interval">
        {
          LogoList.map((val:any)=>{
            return <BusinessLogoCube logo={val}/>
          })
        }
          
       </section>

       <section className="padding-global padding-section-medium">
           <SwitchCube/>
       </section>


       <section className="padding-global padding-section-medium">

          <CategoryCube/>

       </section>

       <section className="padding-global padding-section-medium">
           <SmallFlexCube/>
       </section>


       <section className="padding-global padding-section-medium">
          <BigFlexCube/>
       </section>


       <section className="padding-global padding-section-medium">
          <AskCube/>       
       </section>

    </div>


  );
};

export default HomePage;

