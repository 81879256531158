import React, { createContext, ReactNode, useContext, useMemo } from 'react';  
  
const RouteMetaContext = createContext({});  
  
export function useRouteMetadata() {  
  return useContext(RouteMetaContext);  
}  
  
export function RouteMetaProvider({ children, meta }:{children:ReactNode,meta:any}) { 
  const value = useMemo(() => meta, [meta]);  
  return <RouteMetaContext.Provider value={value}>{children}</RouteMetaContext.Provider>;  
}