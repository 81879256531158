import React, { useEffect, useState } from 'react';
import { DiscordOutlined } from '@ant-design/icons';
import './index.less';
import { useLocation } from 'react-router-dom';
import { useRouteMetadata } from '@/hooks/RouteMetaProvider';
import img1 from '@/images/disscord.png';
import img2 from '@/images/gitbook.png';
import img3 from '@/images/email.png';

const BottomBar = (props: any) => {

  const meta:any = useRouteMetadata()
  const [footHide,setFootHide] = useState(false)

  useEffect(()=>{
    console.log('meta',meta)
    const {hideFooter = false} =meta
    setFootHide(hideFooter)
  },[meta])

  return (
    <>
    {!footHide?<div className='bottom_bar'>
        <div className="main">
           <p>Powered by Hyperion AI</p>
           <div className="jump">
              <div ><img src={img1} alt="" /></div>
              <div ><img src={img3} alt="" /></div>
              <div ><img src={img2} alt="" /></div>
           </div>
        </div>
    </div>:false}
    </>
  );
}

export default BottomBar;
