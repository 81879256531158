import React from 'react';
import './index.less';

interface propsType{
  logo:string
}


const BusinessLogoCube = (props: propsType) => {
  const {logo} = props

  return (
   <div className="BusinessLogoCube animated fadeInUp">
        <img src={logo} alt="" />
   </div>
  );
};

export default BusinessLogoCube;

