import { CloseCircleFilled, PlusOutlined,PictureOutlined } from "@ant-design/icons";
import type { RadioChangeEvent } from 'antd';
import { Radio } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";

import React, { useRef, useEffect, forwardRef, useState } from "react";
import './style.less'

const RadioCustom = forwardRef((props: any, ref) => {
  useEffect(() => {

  }, []);
  const {list,value} = props

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    console.log(value)
    props.onChange(value)
  };

  return <div id='radio_custom'>
      <Radio.Group
        options={list}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
  </div>

});
export default RadioCustom

