import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import LimitTextArea from '@/components/LimitTextArea';
import RadioCustom from '@/components/RadioCustom';
import UploadCustom from '@/components/UploadCustom';
import { useSelector } from 'react-redux';
import { getter } from '@/store/reducers/Global';
import ButtonCustom from '@/components/ButtonCustom';
import { fileToBase64, useValidate } from '@/utils/common';
import { gethostIndex, imgToSpecialIp, uploadFile } from '@/store/reducers/index';
import { BASE_TU_BI_CHUAN,COMMON_PLACEHOLDER } from '@/utils/toastTips';
import { App } from 'antd';
import { Link} from 'react-router-dom';
import '@/assets/iconfont/iconfont.css'
import { useAxios } from '@/hooks/useRequest';

const ConfigBox = (props: any) => {
  const hooksFetch = useAxios()
  const [validate] = useValidate()

  const { uuid ,drawConfig:{specialIpSet}} = useSelector(getter)
  const [specialList,setSpecialList] = useState<any>([])
  const [form, setForm]:any = useState(
    { style: '',prompt: '', imageUrl: '', imageFile: null }
  )
  const {beforeSubmit,submit} = props

  useEffect(() => {
    console.log(1,specialList)
    if(!specialList.length){
    console.log(2)
    const list = listChange(specialIpSet)
      const value = list[0]?.value||''
      setSpecialList(list)
      setForm({...form,style:value})
    }
    return () => {
    };
  }, [specialIpSet])
  const listChange = (obj:any)=>{
    const list = []
    for(let i in obj){
      list.push({label:i,value:obj[i]})
    }
    return list
  }
  const formChange = (val:string,name:string)=>{
    setForm({...form,[name]:val})
  }
  const getUploadFile = async(file: any) => {
    const imageUrl = await fileToBase64(file)
    setForm({...form,imageUrl,imageFile:file})
  }
  const deleteImage = ()=>{
    setForm({...form,imageUrl:'',imageFile:null})

  }

  const submitDraw = async ()=>{
    const newForm:any = {}
    const ruleForm = {
      imageFile:{require:true,tips:BASE_TU_BI_CHUAN}
    }
    // console.log(form)
    if(!validate(ruleForm,form)) return
    beforeSubmit()
    newForm.uid = uuid
    newForm.prompt = form.prompt
    newForm.style = form.style
    newForm.hostIndex = await hooksFetch(gethostIndex())
    newForm.baseImg = await hooksFetch(uploadFile(newForm.hostIndex,form.imageFile))
    hooksFetch(imgToSpecialIp(newForm)).then((res:any)=>{
      setForm({...form,prompt:''})
      deleteImage()
      submit()

    })
  }
  return (
    <div className="SpecialIpStyle_page">

      <div className="classify_title">
        <Link to={'/DrawImage'}><i className='iconfont icon-fanhui'></i></Link >      
        <p>IP Styles</p>
      </div>

      <div className="desc_box">
        <div className="desc_title">Choose IP Style</div>
        <div className='desc_area'>
          <RadioCustom onChange={(val:any)=>formChange(val,'style')} value={form.style} list={specialList}></RadioCustom>
        </div>
      </div>


      <div className="desc_box">
        <div className="desc_title">Description</div>
        <div className='desc_area'>
          <LimitTextArea value={form.prompt} onChange={(e:string)=>formChange(e,'prompt')} placeholder={COMMON_PLACEHOLDER}></LimitTextArea>
        </div>
      </div>

      <div className="desc_box">
        <div className="desc_title">Reference Image</div>
        <div className='desc_area'>
          <UploadCustom imageUrl={form.imageUrl} deleteImg={deleteImage} getUpLoadFile={(file: any) => getUploadFile(file)}></UploadCustom>
        </div>
      </div>

      <div className='submit' onClick={submitDraw}><ButtonCustom>Generate</ButtonCustom></div>




    </div>
  );
};

export default ConfigBox;

