import { Col, InputNumber, Row, Select, Slider } from "antd";
import TextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import React, { useRef, useEffect, forwardRef } from "react";
import './style.less'
interface propsType extends TextAreaProps{
  max?:number,
  value?:string,
  shadowColor?:string,
  onChange?:any
}
const LimitTextArea = forwardRef((props: propsType, ref) => {
  useEffect(() => {

  }, []);
  
  const { max=3000,value="",onChange ,shadowColor="",...params} = props
  const getText = (e:any)=>{
    const text = e.target.value
    onChange(text)
  }
  return <div id="limit_area" style={{ width: '100%', height: '100%', ...shadowColor?{boxShadow:`0px 8px 0px 1px ${shadowColor}`,borderColor:shadowColor}:''}}>
        <TextArea style={{...shadowColor?{borderColor:shadowColor}:''}} onChange={getText} value={value} maxLength={max} autoSize={{minRows:4,maxRows:4}}  {...params} />
        {/* <div className="limit">{value.length}/{max}</div> */}
  </div>

});
export default LimitTextArea

