import React, { useEffect, useState } from 'react';
import './index.less';
import { Anchor } from "antd";
import { Link, NavLink } from 'react-router-dom';
import { DownOutlined, MenuOutlined, BgColorsOutlined } from '@ant-design/icons';
import { Animated } from 'react-animated-css';
import { isMobile } from '@/utils/common';
import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import '@/assets/iconfont/iconfont.css'
import { useRouteMetadata } from '@/hooks/RouteMetaProvider';

const NavBar = (props: any) => {
  const meta:any = useRouteMetadata()
  const [headerHide,setHeaderHide] = useState(false)

  const logo = require('@/images/logo.png')
  const [showChild, setShowChild] = useState(-1)
  const [navListShow, setNavList] = useState(!isMobile())
  const { list } = props
  // useLockBodyScroll(isMobile() && navListShow ? true : false)
  useEffect(()=>{
    console.log('meta',meta)
    const {hidHeader = false} =meta
    setHeaderHide(hidHeader)
  },[meta])

  const navEnter = (index: number) => {
    setShowChild(index)
  }
  const mobileListChange = () => {
    const listShow = !navListShow

    setNavList(listShow)
  }
  const renderDrop = (list: any) => {
    return <div className='drop_box' >
      {
        list.map((drapVal: any) => {
          return <div className="drop_item">
            <div className="icon"><i className={`iconfont ${drapVal.icon}`}></i></div>
            <NavLink className={({ isActive }: any) => `${isActive ? "active" : ""}`} to={drapVal.path}>
              {drapVal.name}
            </NavLink>
          </div>
        })
      }
    </div>
  }

  const renderList = (list: any) => {
    return <div className="nav_list">
      {
        list.map((val: any, index: number) => {
          return (
            <div className="nav_item" onMouseLeave={() => setShowChild(-1)} onMouseEnter={() => navEnter(index)}>
              <div className='nav_title'>
                <NavLink className={({ isActive }: any) => `${isActive ? "active" : ""}`} to={val.path}>
                  {val.name}
                </NavLink>

                {/* 移动端 */}
                {/* {val.children.length && !isMobile() ? <div>
                <DownOutlined className='draw_tyle' size={10} />
              </div> : null} */}
              </div>
              {
                val.children.length ? (
                  <>
                    {/* 移动端 注释*/}
                    {
                      showChild != -1 && <Animated animationInDuration={500} animationIn="zoomIn" animationOut="zoomOut" isVisible={index === showChild}>
                        {renderDrop(val.children)}
                      </Animated>

                    }
                    {/* 移动端解注释 */}
                    {/* {
                  isMobile() ? renderDrop(val.children) :
                    <>
                      {
                        showChild != -1 && <Animated animationInDuration={500} animationIn="zoomIn" animationOut="zoomOut" isVisible={index === showChild}>
                          {renderDrop(val.children)}
                        </Animated>

                      }
                    </>
                } */}
                  </>
                ) : null
              }
            </div>
          )
        })
      }

    </div>
  }

  return (
    <>
    {!headerHide?<div className={`header ${isMobile() && 'header_mobile'}`}>
      <div className={`nav_bar ${isMobile() && 'nav_bar_mobile'}`}>
        <div className="logo_menu">
          <div className="logo" >
            <Link to={'/home'}><img src={logo} alt="" /></Link >
          </div>
          {/* 移动端 */}
          {/* {isMobile() ? <div className='drop_icon' onClick={()=>mobileListChange()}><MenuOutlined /></div> : false} */}
        </div>
        {/* {navListShow ? renderList(list) : false} */}
        {renderList(list)}


      </div>
    </div>:false}
    </>
  );
}

export default NavBar;
