import React, { Children, useEffect, useRef, useState } from 'react';
import './style.less';


const Loading = (props: any) => {


  const { children,imgName='loading'} = props

  return (
    <div id='loading'>
      <div className="loading_animat">
        <img src={require(`@/images/${imgName}.gif`)} alt="" />
       {children? <div className='loading_text'>{children}</div>:false }
      </div>
    </div>
  );
};

export default Loading;

