import { Col, InputNumber, Row, Select, Slider } from "antd";
import React, { useRef, useEffect, forwardRef } from "react";
import './style.less'
const SliderCustom = forwardRef((props: any, ref) => {
  useEffect(() => {

  }, []);
  const { max = 2048, min = 128, value = 512, onChange,...params } = props
  return  <div id="SliderCustom">
    <Slider
     
      defaultValue={30}
      min={min}
      max={max}
      onChange={onChange}
      value={Number(value)}
      {...params}
    />
    </div>

    {/* <div className="unit">px</div> */}

});
export default SliderCustom

