import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import RadioCustom from '@/components/RadioCustom';
import { setFips } from 'crypto';


interface propsType{
  list:any
  onChange:Function
}

const PoseSelect = (props: propsType) => {
  const { list,onChange } = props
  const [typeList,setTyleList] = useState([])
  const [typeForm,setTypeForm] = useState({
    poseStyle:"",pose:''
  })
  useEffect(() => {
    let typeList:any = []
    for(let i in list){
      typeList.push(i)
    }
    setTyleList(typeList)
    const poseList = typeList[0]?list[typeList[0]]:[]
    setTypeForm({
      poseStyle:typeList[0],
      pose:poseList[0]
    })
    return () => {
    };
  }, [])

  
  const formChange = (val:any,name:string)=>{
    setTypeForm({
      ...typeForm,
      [name]:val
    })
  }
  const changePose = (val:string)=>{
    onChange(typeForm.poseStyle,val)
  }
  return (
    <div className="pose_select">

      <div className="classify_title">Select Anlmage</div>

      <div className='pose_classify'>
        <div className="title">Pose Library</div>
        <RadioCustom onChange={(val: any) => formChange(val, 'poseStyle')} value={typeForm.poseStyle} list={typeList}></RadioCustom>
      </div>
      <div className='pose_list'>
        {
          typeForm.poseStyle&&list[typeForm.poseStyle]?.map((val:any)=>{
           return <div onClick={()=>changePose(val)} className={`pose_item ${typeForm.pose===val?'active':''}`}>
              <img src={val} alt="" />
            </div>
          })
        }
      </div>
    </div>
  );
};

export default PoseSelect;

