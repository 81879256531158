import type { CSSProperties } from 'react';
import React from 'react';
import './index.less';
import ShowBox from './ShowBox/index';



const CategoryCube: React.FC = (props:any) => {

  const ShowList = [
    {
      HeadLine: 'LLMs',
      matter: 'Generative AI',
      img:require('@/images/home/show1.png'),
    },
    {
      HeadLine: 'Art',
      matter: 'Text to Image',
      img:require('@/images/home/show2.png'),
    },
    {
      HeadLine: 'Web3',
      matter: 'Insights',
      img:require('@/images/home/show3.png'),
    },
    {
      HeadLine: 'GPU',
      matter: 'Performance',
      img:require('@/images/home/show4.png'),
    },
  ]

  return (
   <div className="CategoryCube">
       
         
       <div className="section-header">
            <h2>WE ARE HYPERION AI</h2>
            <h3>We are empowering innovation and enhancing usability for all users.</h3>
          </div>
          <div className="insights_component flex">
             {
                      ShowList.map(val=>{
                          return <div className='show_cube'>
                                    <ShowBox {...val}/>
                                 </div>
                      })
              }
          </div>

   </div>

  );
};

export default CategoryCube;

