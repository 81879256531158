import React, { useEffect, useRef, useState } from 'react';

import { Button, Modal } from "antd"
import KonvaDraw from '@/views/ImgEdtDemo/knovaDraw';
import SliderInput from '@/components/SliderInput';

const useImageEditorModal = (callback: Function) => {
    useEffect(() => {
    }, [])

    const [visible, setVisible] = useState(false)
    const imgPath = useRef('')
    const konvaRef: any = useRef(null)
    const ImageEditorModal = (file: any) => {
        const [strokeWidth, setStrokeWidth] = useState(15)

        return (
            <Modal
                style={{}}
                title="图片编辑"
                width={'75%'}
                visible={visible}
                onOk={confirm}
                onCancel={cancel}
                okText="确认"
                cancelText="取消"
                bodyStyle={{ height: "100%" }}
            >
                <div style={{}}>
                    <KonvaDraw strokeWidth={strokeWidth} ref={konvaRef} imgurl={imgPath.current}></KonvaDraw>
                    <div>
                        <SliderInput min={5} max={30} value={strokeWidth} onChange={(e: number) => {
                            console.log(e)
                            setStrokeWidth(e)
                        }} />
                        <Button onClick={() => {
                            konvaRef.current.undo()
                        }}>撤销</Button>
                        <Button onClick={() => {
                            konvaRef.current.redo()
                        }}>恢复</Button>
                    </div>

                    {/* <ImageEditor getImg={getImgObj} imgPath={imgPath.current} /> */}
                </div>
            </Modal>
        )
    }

    const openModal = (file: any) => {
        imgPath.current = file
        setVisible(true)
    }
    const confirm = (e: any) => {
        console.log(konvaRef.current.exportImg())
        const base64 = konvaRef.current.exportImg()
        callback({imgEditorObj:base64,cancel})
    }
    const cancel = () => {
        setVisible(false)
    }

    return { cancel, openModal, ImageEditorModal }
}
export default useImageEditorModal