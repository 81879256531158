import { Col, InputNumber, Row, Select, Slider } from "antd";
import React, { useRef, useEffect, forwardRef } from "react";
import './style.less'
import SliderCustom from "../SliderCustom";
const SliderInput = forwardRef((props: any, ref) => {
  useEffect(() => {

  }, []);
  const { max = 2048, min = 128, value = 512, onChange,...params } = props
  return <div className="slider_input" style={{ width: '100%', height: '100%' }}>
    <div className="slider">
    <SliderCustom
     
      defaultValue={30}
      min={min}
      max={max}
      onChange={onChange}
      value={Number(value)}
      {...params}
    />
    </div>
   
    <InputNumber
      min={min}
      max={max}
      style={{}}
      value={value}
      size="small"
      onChange={onChange}
      {...params}
      changeOnWheel
    />
    {/* <div className="unit">px</div> */}



  </div>

});
export default SliderInput

