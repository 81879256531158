import React, { useMemo } from 'react';
import { Table } from 'antd';


const App = () => {
  

  return <iframe style={{width:'100%'}} src="http://121.8.169.179:12006/" title="W3School 在线教程"></iframe>
};

export default App;