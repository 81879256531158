import React, { useEffect, useRef, useState } from 'react';
import './style.less';
import { useSelector, useDispatch } from 'react-redux';
import { backgroundChange, gethostIndex, getter, updateData, uploadFile } from 'store/reducers/index';
import { getter as globalGetter } from 'store/reducers/Global';
import CommonBlock from '@/views/Index/components/CommonBlock';
import SelectCustom from '@/components/SelectCustom';
import SliderInput from '@/components/SliderInput';
import LimitTextArea from '@/components/LimitTextArea';
import ButtonCustom from '@/components/ButtonCustom';
import { Button, message, Modal, Slider } from 'antd';
import UploadCustom from '@/components/UploadCustom';
import useImageEditorModal from '@/hooks/useImageEditorModal';
import { base64ToFile } from '@/utils/common';
import SliderCustom from '@/components/SliderCustom';
import RadioCustom from '@/components/RadioCustom'
import { useAxios } from '@/hooks/useRequest';

const TextDraw = (props: any) => {
  // let imgEditorItem:any = null
  // const imgEditor = (e:any)=>{
  //   imgEditorItem = e
  // }
  const hooksFetch = useAxios()
  const [productImg,setProductImg] = useState("") 
  const imgInfo = useRef({
    info: {},
    imgBase: ''
  })
  const dispatch: any = useDispatch()
  const { modelList, textDraw }: any = useSelector(getter)
  const { uuid }: any = useSelector(globalGetter)
  useEffect(() => {
    // causel()
    console.log(uuid)
    // dispatch(getDrawConfig())
    return () => {
    };
  }, [])

  const drawNum = Array(4).fill(1)
  const modelChange = (val: any) => {
    dispatch(updateData({ key_name: ['textDraw.model'], value: [val] }))

  }
  const promptChange = (val: string) => {
    dispatch(updateData({ key_name: ['textDraw.prompt'], value: [val] }))

  }
  const targetChange = (val: string) => {
    dispatch(updateData({ key_name: ['textDraw.target'], value: [val] }))

  }
  const sizeChange = (type: string, val: number) => {
    let text = 'textDraw.' + type
    console.log(text)
    console.log('=====', textDraw)
    dispatch(updateData({ key_name: [text], value: [val] }))
  }

  const startDraw = async () => {

    const hostIndex = await hooksFetch(gethostIndex())
    console.log(imgInfo.current)
    const info: any = imgInfo.current.info
    // const name = info.name.split('.')[0]
    const imgFile = base64ToFile(imgInfo.current.imgBase, info.name)
    console.log(imgFile)
    const fileMsg = await hooksFetch(uploadFile(hostIndex, imgFile.file))
    console.log(fileMsg)
    const { prompt, target } = textDraw
    const drawBGChange = await backgroundChange({
      uid: uuid,
      image: fileMsg,
      hostIndex, prompt, target

    })
    console.log(drawBGChange)
    // getDetail(drawBGChange)
  }

  // const getDetail = async (id: number) => {
  //   const list = await getDetailAsync({ id })
  //   console.log(list)
  //   const item = list.list[0]
  //   const url = item.images[0]
  //   if (url) {
  //     setProductImg(url)
  //   } else {
  //     setTimeout(() => {
  //       getDetail(id)

  //     }, 5000);
  //   }
  // }

  const getUpLoadFile = (file: any) => {
    console.log(URL.createObjectURL(file))
    console.log('file', file)
    imgInfo.current.info = file
    ImageEditorOpen(file)
  }

  const ImageEditorConfirm = ({ imgEditorObj, cancel }: any) => {
    imgInfo.current.imgBase = imgEditorObj
    cancel()
  }
  const {
    openModal: ImageEditorOpen,
    ImageEditorModal
  } = useImageEditorModal(ImageEditorConfirm)
  return (
    <div className="text_draw">
      <div className='left'>
        <CommonBlock title='图片模型'>
            <div className=''>
              <SelectCustom value={textDraw.model} list={modelList} onChange={modelChange}></SelectCustom>
            </div>
          </CommonBlock>
        <CommonBlock title='图片尺寸'  border={true}>
            <div className='draw_size'>
              <div className="draw_size_box">
                <div className='size_title'>宽：</div>
                <div className='size_ctrl'> <SliderInput value={textDraw.width} onChange={(val:number)=>sizeChange('width',val)} /></div>
              </div>
              <div className="draw_size_box">
                <div className='size_title'>高：</div>
                <div className='size_ctrl'><SliderInput value={textDraw.height} onChange={(val:number)=>sizeChange('height',val)} /></div>
              </div>
            </div>
          </CommonBlock>

          <CommonBlock title='单选'  border={true}>
            <div className='radio_size'>
              <RadioCustom></RadioCustom>
            </div>
          </CommonBlock>

        <CommonBlock title='图生图' border={false}>
          <div className=''>
            <UploadCustom imageUrl={imgInfo.current.imgBase} getUpLoadFile={getUpLoadFile}></UploadCustom>
          </div>
        </CommonBlock>
      </div>
      <div className='right'>
        <CommonBlock title='图片的描述' border={false}>
          <div className=''>
            <LimitTextArea shadowColor="red" onChange={promptChange} value={textDraw.prompt} rows={4} />
          </div>
        </CommonBlock>
        <CommonBlock title='抠图对面' border={false}>
          <div className=''>
            <LimitTextArea onChange={targetChange} value={textDraw.target} rows={4} />
          </div>
        </CommonBlock>
        <div className='draw_ctrl'>
          <Button onClick={startDraw} shape="round">开始绘画</Button>
        </div>
        <div className="">
      <SliderCustom defaultValue={30} disabled={false} />

           <ButtonCustom background='pink' borderColor='red'>generate</ButtonCustom>
        </div>
      </div>
      <ImageEditorModal></ImageEditorModal>
      <img src={imgInfo.current.imgBase} alt="" />
      <img src={productImg} alt="" />
    </div>
  );
};

export default TextDraw;

