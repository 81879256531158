import type { CSSProperties } from 'react';
import React from 'react';
import {Carousel} from 'antd';
import './index.less';


const ImgList:any = [
  require('@/images/home/carousel/card1.png'),
  require('@/images/home/carousel/card2.png'),
  require('@/images/home/carousel/card3.png'),
  require('@/images/home/carousel/card4.png'),
  require('@/images/home/carousel/card5.png'),
  require('@/images/home/carousel/card6.png'),
  require('@/images/home/carousel/card7.png'),
  require('@/images/home/carousel/card8.png'),
]

const BigFlexCube: React.FC = (props:any) => {


  return (
    <div className="carousel_page">
          <Carousel autoplay autoplaySpeed={3000} slidesToScroll={1} slidesToShow={3} >
      {
          ImgList.map((img:any)=>{
            return <img src={img} alt="" />
          })
        }
     </Carousel>
    </div>


  );
};

export default BigFlexCube;

