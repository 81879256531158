import { CloseCircleFilled, PlusOutlined,PictureOutlined } from "@ant-design/icons";
import { Col, InputNumber, Row, Select, Slider, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import React, { useRef, useEffect, forwardRef, useState } from "react";
import './style.less'
import UploadStyle from "../UoLoadStyle";

interface propsType{
  getUpLoadFile:Function,
  imageUrl?:string
  deleteImg?:Function
}

const UploadCustom = forwardRef((props: propsType, ref) => {
  useEffect(() => {

  }, []);
  const {getUpLoadFile,imageUrl,deleteImg=()=>{}} = props

  const uploadButton = (
    <div className="upload_in">
      <div className="upload_in">
         <PictureOutlined />
      </div>
      {/* <div className="ant-upload-text">Upload</div> */}
    </div>
  );
  const beforeUpload=(file: RcFile, FileList: RcFile[])=>{
    getUpLoadFile(file)
    return true
  }
  const handleChange = (info:UploadChangeParam)=>{
    console.log('info',info,info.fileList)
  }


  const reomveImg = (e:any)=>{
      e.stopPropagation()
      deleteImg()
  }

  return <div className={`upload ${imageUrl ? "upload_done" : ""}`}>
   <Upload
        name="avatar"
        listType="picture"
        className="avatar-uploader"
        showUploadList={false}
        action=""
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <UploadStyle imageUrl={imageUrl} deleteImg={(e:Event)=>reomveImg(e)} />
      </Upload>
  </div>

});
export default UploadCustom

