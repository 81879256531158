import React, { useEffect, useRef, useState } from 'react';
import './style.less';
const CommonBlock = (props:any) => {

  useEffect(() => {
    // causel()
    return () => {    
    };
  })
  const { border=true,title,children } = props
  return (
    <div className={["common_block",border?'border':''].join(' ') }>
      <div className='title'>{title}</div>
      <div className='content'>
        {children}
      </div>
    </div>
  );
};

export default CommonBlock;

