import React, { useEffect, useRef, useState } from 'react';
import './style.less';
import { DownCircleOutlined } from '@ant-design/icons';
const CommonBlock = (props:any) => {
  const [contentShow,setContentShow] = useState(true)
  useEffect(() => {
    // causel()
    return () => {    
    };
  })
  const { title,children } = props
  const showContent = ()=>{
    // const bool = contentShow
    setContentShow(!contentShow)
  }
  return (
    <div className={`common_block`}>
      <div className="common_header">
        <div className='common_title'>{title}</div>
        <div className="contry_icon" onClick={showContent}><DownCircleOutlined rotate={!contentShow?180:0} /></div>
      </div>
      {contentShow?<div className="common_content">
        {children}
      </div>:false}
    </div>
  );
};

export default CommonBlock;

