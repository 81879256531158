import React, { useEffect, useRef, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Button} from 'antd';
import './index.less';
import img from '@/images/drawing/ttl_1.png';
import GradientRamp from '@/views/App/components/GradientRamp/index';


const BigCard = (props: any) => {
  

  const navigate = useNavigate()

  const jump = (url:string)=>{
    console.log(123)
    navigate(url)
  }



  return (
    <div className="big_card_page"  onClick={()=>jump('/DrawImage/TextToImage')}>
      <GradientRamp  startColor="#e9fdff" endColor="#e7fff8" direction="45deg" hover={['#cffefa','#cffefa']}>
          <div className="flex">
                    <div className="explain">
                        <div className="txt">
                            <h4>Text-to-Image</h4>
                            <p>Generate stunning images based on textual description.</p>
                        </div>
                        <div className="btn">
                          {/* <Button  shape="round" size={'middle'}>
                              Try now
                          </Button> */}
                          <Button shape="round" className='started'>Try now</Button>
                        </div>
                    </div>
                    <div className="img"><img src={img} alt="" /></div>
          </div>
      </GradientRamp>
   </div>
    


  );
};

export default BigCard;

