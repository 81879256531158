import React, { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';
import { Tabs,Button,Collapse} from 'antd';
import './index.less';
import img from '@/images/drawing/ttl_2.png';
import GradientRamp from '@/views/App/components/GradientRamp/index';


const SmallCard = (props: any) => {
  


  const { hover=[],HeadLine,matter,img,startColor,endColor,href} = props

  return (
<div className="small_card_page">
      <GradientRamp hover={hover} startColor={startColor} endColor={endColor} direction="45deg">
            <div className="block">
                <div className="img"><img src={img} alt="" /></div>

                <div className="explain">
                    <div className="txt">
                        <h4>{HeadLine}</h4>
                        <p>{matter}</p>
                    </div>
                    <div className="btn">
                      {/* <Button shape="round" size={'middle'} href={href}>
                          Try now
                      </Button> */}
                      <Button shape="round" className='started' href={href}>Try now</Button>
                    </div>
                </div>  
            </div>
               
        
    </GradientRamp>
    </div>
  );
};

export default SmallCard;

