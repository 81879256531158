import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { getImageDimensions } from '@/utils/common';
import { Button } from 'antd';
import KnovaDraw from './knovaDraw';
// import imgurl from '../../images/BTCbg.png'
const Index = (props:any) => {
  const dispatch = useDispatch();
  const KonvasRef:any = useRef()
  const imgurl = require('@/images/home/bus_logo1.png').default
  useEffect(() => {
  
    return () => {    
    };
  },[])

  return (
    <div className="index">
      <div className='canvasBox'>
        <KnovaDraw ref={KonvasRef} imgurl={imgurl} />
      </div>
      <Button onClick={()=>{
        KonvasRef.current.undo()
      }}>上一步</Button>
       {/* <ImageEditor imgPath= {imgurl} /> */}
    </div>
  );
};

export default Index;

