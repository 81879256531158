import React, { forwardRef, LegacyRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './index.less';
import { Stage, Layer, Line, Circle, Image } from 'react-konva';

import { getImageDimensions } from '@/utils/common';
import KonvaImage from './components/KonvaImage'

interface propsConfig {
  imgurl: File | string,
  strokeWidth?: number,
  strokeColor?: string
}
const KonvaDraw = forwardRef((props: propsConfig, compRef: any) => {
  const [tool, setTool] = useState('pen');
  const [lines, setLines]: any = useState([]);
  const [hisLines, setHisLines]: any = useState([])
  const isDrawing = useRef(false);
  const KonvaRef: any = useRef()
  const stageBoxRef:any = useRef()
  const [stageWH, setStageWH] = useState({ width: 512, height: 512 })
  const [containWh, setContentWH] = useState({ width: 512, height: 512 })
  const [bestScale, setBestScale] = useState(0)
  const [imgType, setImgType] = useState('type/jpg')
  const [ImgUrl,setImgUrl] = useState('')
  const { imgurl, strokeWidth = 15, strokeColor = "#df4b26" } = props
  useEffect(() => {
    if (imgurl instanceof File) {
      const url = URL.createObjectURL(imgurl)
      console.log(url)
      setImgUrl(url)
      setImgType(imgurl.type)
      getStageWH(url)
    } else {
      getStageWH(imgurl)
    }
  }, [])
  useImperativeHandle(compRef, () => ({
    // 暴露给父组件的方法  
    undo: () => {
      const newLines = lines.slice(0, lines.length - 1)
      setLines(newLines)
    },
    redo: () => {
      if (lines.length >= hisLines.length) {

        return
      }
      lines.splice(lines.length - 1, 0, hisLines[lines.length]);
      setLines(lines.concat());
    },
    exportImg: () => {
   
      const base64 = KonvaRef.current.getStage().toDataURL({
        mimeType: imgType, quality: 1, pixelRadio: 2
      })
      return base64
    },
    scale: bestScale,
    lineStep:lines,
    hisLineStep:hisLines
  }));
  const handleMouseDown = (e: any) => {
    const strokeW= stageWH.width*strokeWidth*2/1000
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    // console.log(lines)
    setLines([...lines, { tool, strokeWidth:strokeW,opacity:1, points: [pos.x / bestScale, pos.y / bestScale] }]);
    setHisLines([...lines, { tool, strokeWidth:strokeW,opacity:1, points: [pos.x / bestScale, pos.y / bestScale] }])
  };
  const getStageWH = (imgurl: string) => {
    getImageDimensions(imgurl).then((res: any) => {
      const winWidth = stageBoxRef.current.offsetWidth
      const winHeight = stageBoxRef.current.offsetHeight
      console.log('=========',stageBoxRef,winWidth,winHeight)
      const { width: imgWidth, height: imgHeight } = res
      // if(imgw>imgHeight)
      const bestScale = Math.min(
        winWidth / imgWidth,
        winHeight / imgHeight
      )
      setBestScale(bestScale)
      const newWidth = imgWidth * bestScale;
      const newHeight = imgHeight * bestScale;
      // // 居中图像  
      // imgRef.current.x(0);  
      // imgRef.current.y(0);  

      // // 设置缩放后的尺寸  
      // imgRef.current.width(newWidth);  
      // imgRef.current.height(newHeight);
      // setStageWH({width:newWidth,height:newHeight})
      console.log(newWidth,newHeight)
      setContentWH({ width: newWidth, height: newHeight })
      setStageWH(res)
    })
  }
  const handleMouseMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    // console.log(bestScale, point)
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x / bestScale, point.y / bestScale]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
    setHisLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
    console.log(lines)
  };
  return (
    <div style={{width:'100%',height:"100%"}}>
      <div ref={stageBoxRef} style={{width:'100%',height:"100%"}}>
        <Stage
          style={{...containWh}}
          className='stageBox'
          ref={KonvaRef}
          width={stageWH.width}
          height={stageWH.height}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <Layer>
            <KonvaImage imgurl={ImgUrl} />
            {/* <KonvaImage ref={imgRef} imgurl={props.imgurl} /> */}
            {lines.map((line: any, i: number) => (
              <Line
                key={i}
                points={line.points}
                opacity={line.opacity}
                stroke={strokeColor}
                strokeWidth={line.strokeWidth}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
              // globalCompositeOperation={
              // // pen 擦线：eraser画线
              //   line.tool === 'eraser' ? 'destination-out' : 'source-over'
              // }
              />
            ))}
          </Layer>
        </Stage>
      </div>
    </div>
  );
});

export default KonvaDraw;

