import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowUpOutlined, CopyOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import useCopyToClipboard from '@/hooks/useCopyToClipboard';
const ChatRecord = (props: any) => {
  const defaultHeader = require('@/images/logo.png')
  const dispatch = useDispatch();
  const {isCopied,copyText} = useCopyToClipboard()
  const { list } = props
  useEffect(() => {

    return () => {
    };
  }, [])

    const copy=(text:string)=>{
      copyText(text)
    }

  return (
    <div className="chat_record_box">
      {list.map((val: any) => {
        return <div className={`record_item ${val.role=='user'?'user_bg':'answer_bg'}`}  key={val.id}>
          <div className="record_header">
            <div className="header_img"><img src={val.header || defaultHeader} alt="" /></div>
            <div className="header_name">{val.name}</div>
            <div className="copy" onClick={()=>copy(val.content)}><CopyOutlined /></div>
          </div>
          <div className="record_content">
            {val.content}
          </div>
        </div>
      })}
    </div>
  );
};

export default ChatRecord;

