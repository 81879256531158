import axios, { AxiosResponse } from 'axios';
import { App } from 'antd';
import { paramsHandler, request_ip } from '@/utils/common';

// 自定义Hook  



function useAxios() {
    const { message } = App.useApp()
    const fetch = <K,T extends { result?: number; errno?: string; data: K }>(
        params: any,
    ) => {
        // const {message} = App.useApp()
        return new Promise<K|boolean>((resolve, reject) => {
            params.url = `${request_ip}${params.url}`;
            const method = params.method || 'post'
            const data = method == 'post' ? paramsHandler(params.data || {}) : params.data;
            console.log('data', data)
            axios({
                ...params,
                method: method,
                data: data,
                params: method == 'get' ? data : {},
                withCredentials: true,
                timeout: 5000
            })
                .then((res: AxiosResponse<T>) => {
                    if (typeof res == 'string') {
                        res = JSON.parse(res);
                    }

                    const rResult: any = res.data;
                    if (rResult && rResult.code == 200) {
                        resolve(rResult.data);
                    } else {
                        console.log('进来了')
                        message?.open({ content: 'error, please try again' })
                    }
                })
                .catch((error) => {
                    message?.open({ content: 'error, please try again' })

                    console.log('进来了2')
                    resolve(false);
                });
        });
    };
    const hooksFetch = (fn: any) => {
        return fn(fetch)
    }
    // 初始状态  
    return hooksFetch
}



export { useAxios }
