import type { CSSProperties } from 'react';
import React from 'react';
import { PlusOutlined ,CloseOutlined} from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { Collapse, theme } from 'antd';
import './index.less';

const text1 = `
  Hyperion AI is a platform that aggregates AI tools from Web2 and Web3, offering users 
  access to a variety of AI solutions, including advanced text-to-image generation.
`;
const text2 = `
  Our text-to-image tool combines the precision of ComfyUI with a user-friendly interface. 
  It allows high-detail adjustments while lowering the barrier to use, making it accessible without sacrificing control.
`;
const text3 = `
  Vessel Points are rewards earned on Hyperion AI for prompts made on the platform.
   They can be accumulated and used to participate in the Hyperion Launchpad.
`;
const text4 = `
  The Hyperion Launchpad provides GPU power, traffic acquisition, and liquidity to 
  support AI crypto projects, ensuring sustainable development and growth.
`;

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
  {
    key: '1',
    label: 'What is Hyperion AI?',
    children: <p>{text1}</p>,
    style: panelStyle,
  },
  {
    key: '2',
    label: 'What makes Hyperion AI text-to-image tool unique?',
    children: <p>{text2}</p>,
    style: panelStyle,
  },
  {
    key: '3',
    label: 'What are Vessel Points?',
    children: <p>{text3}</p>,
    style: panelStyle,
  },
  {
   key: '4',
   label: 'How does the Hyperion Launchpad support AI projects?',
   children: <p>{text4}</p>,
   style: panelStyle,
 },
];

const AskCube: React.FC = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom:10,
    borderRadius:'15px',
    background: ' transparent ',
    border: '1px solid #e7ddda',
  };

  return (
   <div className="AskCube">
      <div className="faq_header"><h3>Frequently Asked Questions</h3></div>  
      <div className="faq_list">
         <Collapse
            bordered={false}
            // defaultActiveKey={['1']}//默认全部合上
            expandIcon={({ isActive }) => isActive ? <CloseOutlined /> : <PlusOutlined />}
            expandIconPosition='right'
            size='large'
            style={{ background: token.colorBgContainer }}
            items={getItems(panelStyle)}
         />
      </div>

   </div>

  );
};

export default AskCube;

