import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import { useSelector, useDispatch } from 'react-redux';

const DemoPage = (props:any) => {
  useEffect(() => {
  
    return () => {    
    };
  },[])

  return (
    <div className="demo_page">
      
    </div>
  );
};

export default DemoPage;

