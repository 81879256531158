import { Select } from "antd";
import React, { useRef, useEffect, forwardRef } from "react";
import './style.less'
import { SizeType } from "antd/lib/config-provider/SizeContext";
interface propsType{
  list:[],
  value?:string,
  size?:SizeType,
  onChange?:(value:any)=>void,
  placeholder?:String
}
const SelectCustom = forwardRef((props: propsType, ref) => {
  useEffect(() => {

  }, []);
  const { Option } = Select;
  const { list, value, onChange=()=>{}, placeholder,size='small',...params } = props
  return <div style={{ width: '100%'}} id="select_custom">
    <Select
      onChange={onChange}
      placeholder={placeholder || '请选择'}
      value={value}
      // variant="filled"
      style={{ width: '100%' }}
      size={size}
      {...params}
      >
      {
        list.map((val: { label: string, value: string | number }, index: number) => {
          return <Option key={val.value} value={val.value}>{val.value}</Option>
        })
      }
    </Select>
  </div>

});
export default SelectCustom
//  const ImageEditor = forwardRef((props, ref) => {
//   const EditRef = useEditor();

//   return <div ref={EditRef} /></div>;
// });
